import * as React from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardOverflow from '@mui/joy/CardOverflow';
import Divider from '@mui/joy/Divider';
import Typography from '@mui/joy/Typography';

export default function CardItem(params) {

    const { link, logo, itemName, itemDate, itemDateMessage, city, itemSize } = params;

    return (

        <>
            <div className='single-card'>
                <a href={link} target='_blank' className="cardLink" rel="noreferrer">
                <Card variant="outlined" sx={{ width: 240, minWidth: 240 }}>
                    <CardOverflow>
                        <AspectRatio ratio={itemSize}>
                            <img
                                src={logo}
                                srcSet={logo}
                                loading="lazy"
                                alt=""
                            />
                        </AspectRatio>
                    </CardOverflow>
                    <CardContent>
                        <Typography level="title-md">{itemName}</Typography>
                        <Typography level="body-sm">{itemDateMessage}: {itemDate}</Typography>
                    </CardContent>
                    <CardOverflow variant="soft" sx={{ bgcolor: 'background.level1' }}>
                        <Divider inset="context" />
                        <CardContent orientation="horizontal">
                            <Typography
                                level="body-xs"
                                textColor="text.secondary"
                                sx={{ fontWeight: 'md' }}
                            >
                                {city}
                            </Typography>

                        </CardContent>
                    </CardOverflow>
                </Card>
                </a>
            </div>
        </>

    );
};
