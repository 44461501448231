import { Divider } from "@mui/material";
import InfiniteLoopSlider from '../Animations/InfiniteScrollAnimation';

export default function Footer(params) {
    return (
        <>
            <Divider />
            <section className='' id="footer">
                <InfiniteLoopSlider />
                <img src='am-logo-big.png' className='footer-logo' alt="logo Alan Moreira" />
                <InfiniteLoopSlider />
            </section>
        </>
    );
};
