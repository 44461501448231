import { Divider } from "@mui/material";
import React from "react";

export default function InfiniteScrollAnimation(params) {

    const TAGS = [
        'Java', 'NodeJS', 'Python','Kanban', 'PHP', 'C/C++',  'SCRUM', 'JavaScript', 'Lean', 'HTML/CSS', 'SQL Server', 'PMBOK', 'PostgreSQL', 'MySQL', 'MongoDB', 'Six Sigma', 'Power BI', 'Spring Boot'
    ];
    const DURATION = 200000;
    const ROWS = 2
    ;
    const TAGS_PER_ROW = 100;

    const random = (min, max) => Math.floor(Math.random() * (max - min)) + min;
    const shuffle = (arr) => [...arr].sort(() => 0.5 - Math.random());

    const InfiniteLoopSlider = ({ children, duration, reverse = false }) => {
        return (
            <div
                className="loop-slider"
                style={{
                    "--duration": `${duration}ms`,
                    "--direction": reverse ? "reverse" : "normal"
                }}
            >
                <div className="inner">
                    {children}
                    {children}
                </div>
            </div>
        );
    };

    const Tag = ({ text }) => (
        <div className="tag">
            {text}
        </div>
    );


    return (

        <>
          
            <div className="app">

                <div className="tag-list">
                    {[...new Array(ROWS)].map((_, i) => (
                        <InfiniteLoopSlider
                            key={i}
                            duration={random(DURATION - 5000, DURATION + 5000)}
                            reverse={i % 2}
                        >
                            {shuffle(TAGS)
                                .slice(0, TAGS_PER_ROW)
                                .map((tag) => (
                                    <Tag text={tag} key={tag} />
                                ))}
                        </InfiniteLoopSlider>
                    ))}
                </div>
            </div>
        </>
    );
};



