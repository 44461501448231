import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import BackToTop from '../Utils/BackToTop';

export default function Menu(params) {

    const drawerWidth = 240;
    const navItems = [
        { label: 'About', linkTo: '#about' },
        { label: 'Professional', linkTo: '#professional' },
        { label: 'Education', linkTo: '#education' },
        { label: 'Certifications', linkTo: '#certifications' },
        { label: 'Contact', linkTo: '#contact' }
    ];

    const { window } = params;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Typography variant="h6" sx={{ my: 2 }}>
                <img src='am-logo-black.png' className='menu-logo' alt="logo Alan Moreira" />
            </Typography>
            <Divider />
            <List>
                {navItems.map((item) => (
                    <a href={item.linkTo} className='menu-link'>
                        <ListItem key={item.label}>
                            <ListItemButton sx={{ textAlign: 'center' }} >
                                <ListItemText primary={item.label} />
                            </ListItemButton>
                        </ListItem>
                    </a>
                ))}
            </List>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <>
            <BackToTop />
            <Box sx={{ display: 'flex' }} id="back-to-top-anchor">
                <CssBaseline />
                <AppBar component="nav" position='relative'>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2, display: { sm: 'none' } }}
                        >
                            <MenuIcon />
                        </IconButton>
                        {<span className='navbar-mobile-title'>Alan Moreira</span>}
                        <Typography
                            variant="h6"
                            component="div"
                            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                        >
                            <img src='am-logo.png' className='menu-logo' alt="logo Alan Moreira" />

                        </Typography>
                        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                            {navItems.map((item) => (
                                <a href={item.linkTo}>
                                    <Button key={item.label} sx={{ color: '#fff' }}>
                                        {item.label}
                                    </Button>
                                </a>
                            ))}
                        </Box>
                    </Toolbar>
                </AppBar>
                <nav>
                    <Drawer
                        container={container}
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                    >
                        {drawer}
                    </Drawer>
                </nav>
            </Box>

        </>
    );
};
